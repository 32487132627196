import { HandleActionPayload, PageId, WidgetsId } from '../../constants';
import { BasePageMigrator } from './base-page-migrator';
import {
  getBookingsDefId,
  getPageData,
  getTPAMainSectionPageRef,
  getTPAMainSectionStructure,
  isBookingsCheckoutInstalled, isBookingsListInstalled, isOldBookingsListWidgetExist,
  updatePageData,
} from '../editor-sdk-actions';
import { MigrationHandler } from '../../migration/migration-handler';
import {
  getSchedulerStyles,
  setBookingsListStyles,
} from '../list-migration/schedule-styles-migration';
import { addBookCheckoutPage } from '../pages-actions';
import { addBookingsPagesAsPanel } from '../pages-panel-actions';

export class ServiceListMigrator extends BasePageMigrator {
  constructor(protected editorSDK, protected appToken, protected instance, protected handleActionPayload: HandleActionPayload) {
    super(editorSDK, appToken, instance, handleActionPayload);
  }

  public async shouldMigrate(): Promise<boolean> {
    return (
      !(await isBookingsCheckoutInstalled(this.editorSDK)) ||
      !(await isBookingsListInstalled(this.editorSDK)) ||
      !!(await isOldBookingsListWidgetExist(this.editorSDK, this.appToken))
    );
  }

  public async execute() {
    const bookingsDefId = await getBookingsDefId(this.editorSDK);
    const colorsMap = await this.editorSDK.document.theme.colors.getAll();
    const migratory = new MigrationHandler(
      this.editorSDK,
      this.appToken,
      bookingsDefId,
    );
    let schedulerSectionStructure = await getTPAMainSectionStructure(
      this.editorSDK,
      this.appToken,
      bookingsDefId,
    );

    // try fix the site
    if (!schedulerSectionStructure) {
      try {
        await this.addBookingsPage(PageId.BOOKINGS_LIST);

        schedulerSectionStructure = await getTPAMainSectionStructure(
          this.editorSDK,
          this.appToken,
          bookingsDefId,
        );
      } catch (e) {
        const errorMessage = `${(e?.message ? e.message : JSON.stringify(e))} - errorCode: ADD_BOOKINGS_LIST_FAILED`;
        console.log(errorMessage);
      }
    }

    if (!schedulerSectionStructure) {
      throw new Error('schedulerSectionStructure not found in getTPAMainSectionStructure for bookingsListInstalled - errorCode: SCHEDULER_NOT_FOUND_BOOKINGS_LIST');
    }

    // add bookings service list
    if (
      !(await isBookingsListInstalled(this.editorSDK)) ||
      (await isOldBookingsListWidgetExist(this.editorSDK, this.appToken))
    ) {
      const schedulerStyle = await getSchedulerStyles(
        schedulerSectionStructure.style,
        colorsMap,
      );
      console.log('New Properties:');
      console.table(schedulerStyle);

      await migratory.migrateSection(
        schedulerSectionStructure,
        WidgetsId.BOOKINGS_LIST_PAGE,
        PageId.BOOKINGS_LIST,
      );

      const compId = schedulerSectionStructure.id;

      await setBookingsListStyles(
        this.editorSDK,
        this.appToken,
        schedulerStyle,
        compId,
      );
    }

    // add bookings checkout page
    if (!(await isBookingsCheckoutInstalled(this.editorSDK))) {
      const schedulerSectionRef = await getTPAMainSectionPageRef(
        this.editorSDK,
        this.appToken,
        bookingsDefId,
      );
      const schedulerData = await getPageData(
        this.editorSDK,
        this.appToken,
        schedulerSectionRef,
      );
      const schedulerStyle = await getSchedulerStyles(
        schedulerSectionStructure.style,
        colorsMap,
      );
      console.log('New Properties:');
      console.table(schedulerStyle);

      const bookCheckoutPage = await addBookCheckoutPage(
        this.editorSDK,
        bookingsDefId,
        this.appToken,
        schedulerSectionStructure.style,
      );
      const bookCheckoutPageData = await getPageData(
        this.editorSDK,
        this.appToken,
        bookCheckoutPage.pageRef,
      );

      if (schedulerData?.pageBackgrounds?.desktop?.ref?.id && bookCheckoutPageData?.pageBackgrounds?.desktop?.ref?.id) {
        schedulerData.pageBackgrounds.desktop.ref.id = bookCheckoutPageData.pageBackgrounds.desktop.ref.id;
      }

      if (schedulerData?.pageBackgrounds?.mobile?.ref?.id && bookCheckoutPageData?.pageBackgrounds?.mobile?.ref?.id) {
        schedulerData.pageBackgrounds.mobile.ref.id = bookCheckoutPageData.pageBackgrounds.mobile.ref.id;
      }

      await updatePageData(
        this.editorSDK,
        this.appToken,
        bookCheckoutPage.pageRef,
        {
          pageSecurity: { ...schedulerData?.pageSecurity },
          pageBackgrounds: { ...schedulerData?.pageBackgrounds },
        },
      );
    }

    await addBookingsPagesAsPanel(this.editorSDK, this.appToken);
  }

  protected get widgetId() {
    return '54d912c5-52cb-4657-b8fa-e1a4cda8ed01';
  }

  public get widgetName() {
    return 'ServiceListMigrator';
  }

  public get stepTranslate() {
    return 'service-page-migration.progress-bar.step-1';
  }
}
