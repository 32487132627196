import {
  addBookingsPagesAsPanel,
  createBookCheckoutState,
  createBookingFormState,
  createCalendarPageState,
  createServicePageState,
} from './pages-panel-actions';
import { EditorScriptApi } from '../api/api';
import { Property } from '@wix/ambassador-business-server/types';
import { IS_CART_ENABLED_CUSTOM_PROPERTY } from '../constants'

export async function handlePagesPanelMigration(sdk, appToken, allSitePages) {
  await createBookCheckoutState(sdk, appToken, allSitePages);
  await createServicePageState(sdk, appToken, allSitePages);
  await createCalendarPageState(sdk, appToken, allSitePages);
  await createBookingFormState(sdk, appToken, allSitePages);
  await addBookingsPagesAsPanel(sdk, appToken);
}

export function shouldProposeMigration(editorSdk, isAdi, appToken, instance) {
  const isFromTemplate = !JSON.parse(atob(instance.split('.')[1])).metaSiteId;
  return !isAdi && !isFromTemplate;
}

export const updateIsCartEnabledBusinessProperty = async (editorScriptApi: EditorScriptApi, instance: any, state: boolean) => {
  const property: Property = {propertyName: IS_CART_ENABLED_CUSTOM_PROPERTY, value: state ? 'true' : 'false'};
  await editorScriptApi.updateBusinessCustomProperties(instance, property);
}

export const getIsCartEnabledBusinessProperty = async (editorScriptApi: EditorScriptApi, instance: any): Promise<boolean | undefined> => {

  const businessCustomProperties = await editorScriptApi.getBusinessCustomProperties(instance);
  const isCartEnabled = businessCustomProperties?.find((property) => property?.propertyName === IS_CART_ENABLED_CUSTOM_PROPERTY);

  if (isCartEnabled?.value?.toLocaleLowerCase() === 'true') {
    return true;
  } else if (isCartEnabled?.value?.toLocaleLowerCase() === 'false') {
    return false;
  }

  return undefined;
}
