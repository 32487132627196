import { HandleActionPayload, PageId } from '../../constants';
import { ComponentStructure } from '../../migration/domain';
import { getNewServicePageData } from '../service-page-migration/settings-data-transpiler';
import { getNewServicePageStyles } from '../service-page-migration/styles-transpiler';
import { BasePageMigrator } from './base-page-migrator';
import { EditorScriptApi } from '../../api/api';
import { isServicePageInstalled } from '../editor-sdk-actions';
import { addBookingCheckoutPage } from '../pages-actions';

export class ServicePageMigrator extends BasePageMigrator {
  constructor(
    protected editorSDK,
    protected appToken,
    protected instance,
    protected handleActionPayload: HandleActionPayload,
    private editorScriptApi: EditorScriptApi,
  ) {
    super(editorSDK, appToken, instance, handleActionPayload);
  }

  public async shouldMigrate(): Promise<boolean> {
    const servicePageInstalled = await isServicePageInstalled(this.editorSDK);

    return !servicePageInstalled;
  }

  public async execute() {
    try {
      await this.addBookingsPage(PageId.BOOKINGS_SERVICE_PAGE);
    } catch (e) {
      const errorMessage = `${(e?.message ? e.message : JSON.stringify(e))} - errorCode: ADD_BOOKINGS_PAGES`;
      throw new Error(errorMessage);
    }

    let siteStructure = await this.getSiteStructure();
    let compStructuresCheckout: ComponentStructure = this.findWidgetStructure(
      siteStructure,
      this.bookingCheckoutWidgetId,
      PageId.BOOKINGS_CHECKOUT
    );

    if (!compStructuresCheckout) {
      await addBookingCheckoutPage(this.editorSDK, this.appToken);

      siteStructure = await this.getSiteStructure();
      compStructuresCheckout = this.findWidgetStructure(
        siteStructure,
        this.bookingCheckoutWidgetId,
        PageId.BOOKINGS_CHECKOUT
      );

      if (!compStructuresCheckout) {
        throw new Error('Bookings Checkout Page not exist twice - errorCode: ff0ff21b-1373-4bdc-8ee5-8fb1d02e46a4');
      }
    }

    const compStructuresServicePage: ComponentStructure = this.findWidgetStructure(siteStructure, this.widgetId, PageId.BOOKINGS_SERVICE_PAGE);

    try {
      if (compStructuresCheckout.style?.style?.properties) {
        console.log('Old Service Page Properties:');
        console.log(compStructuresCheckout);
        console.table(compStructuresCheckout.style.style.properties);
        console.log('New Service Page structure: (Before Change)');
        console.log(compStructuresServicePage);
        console.table(compStructuresServicePage.style?.style?.properties);
        const newServicePageData = getNewServicePageData(
          compStructuresCheckout.style.style.properties,
        );
        console.log('Layouts to write:');
        console.table(newServicePageData);

        if (newServicePageData) {
          await this.updateComponentSettings(
            compStructuresServicePage.id,
            newServicePageData,
          );
        }
        const newServicePageStyles = getNewServicePageStyles(
          compStructuresCheckout.style.style.properties,
        );
        console.log('Styles to write:');
        console.table(newServicePageStyles);

        if (newServicePageStyles) {
          await this.updateComponentStyles(
            compStructuresServicePage.id,
            newServicePageStyles,
          );
        }
      } else {
        console.log('Old service page is plain, no styles to migrate.');
      }
    } catch (e) {
      const errorMessage = `${(e?.message ? e.message : JSON.stringify(e))} - errorCode: UPDATE_COMPONENT`;
      throw new Error(errorMessage);
    }

    try {
      await this.editorScriptApi.migrateMediaGallery();
    } catch (e) {
      const errorMessage = `Migrate Media Gallery Failed | ${(e?.message ? e.message : JSON.stringify(e))}`;
      throw new Error(errorMessage);
    }

    await this.createPageState(PageId.BOOKINGS_SERVICE_PAGE, 'servicePage');
  }

  protected get widgetId() {
    return 'a91a0543-d4bd-4e6b-b315-9410aa27bcde';
  }

  public get widgetName() {
    return 'ServicePageMigrator';
  }

  public get stepTranslate() {
    return 'service-page-migration.progress-bar.step-1';
  }
}
